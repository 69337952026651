<template>
  <div class="recovery_pass_container">
    <div class="recovery_pass_form">
      <div class="recovery_pass_form_container">
        <div class="recovery_pass">
          <div class="recovery_form">
            <div class="recovery_pass_label">{{ $t('Password_recovery') }}</div>
            <div class="recovery_pass_inputs">
              <div class="input_email_container">
                <input class="input_email" :placeholder="$t('Email')" v-model="email"/>
                <span v-if="$v.email.$dirty && $v.email.$invalid" class="text-danger">{{ $t('Enter_a_valid_mail') }}</span>
              </div>
            </div>
            <div @click="recoveryPassword" class="recovery_pass_btn_next">
              {{ $t('Further') }}
            </div>
            <a href="/signin" class="recovery_pass_on_main">{{ $t('come_back') }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="signin_logo">
      <div class="signin_logo_container"></div>
    </div>
  </div>
</template>
<script>
import LoginForm from '@/components/LoginForm'
import {mapActions} from "vuex";
import {minLength, minValue, required, email} from "vuelidate/lib/validators";
import Vue from "vue";

export default {
  name: 'Login',
  components: {
    LoginForm
  },

  data() {
    return {
      email: '',

    }
  },
  methods: {
    recoveryPassword() {
      let postData = {
        email: this.email,
      }
      this.$v.$touch()
      if (this.$v.email.$invalid) return

      localStorage.setItem('email', this.email)
      this.$http.post(API_ROOT + `/api/auth/email/restore/send`, postData)
          .then((res) => {
            Vue.toastr({
              message: 'Success',
              description: res.body.message,
              type: 'success'
            })
          })
          .catch((err) => {

          })
    },
  },
  validations: {
    email: {
      required,
      email
    },
  },
  watch:{
    $route(to, from) {
      console.log(window.location.href);
      console.log(this.$route.path);
      console.log(this.$route.params);
    }
  }

}
</script>
